<template>
  <add-to-card-button
    :selected-options="selectedOptions"
    :product="product"
    :is-reset-settings="isResetSettings"

    @errorList="(errorList)=>emits('errorList',errorList)"
  >
    <template #cartTemplateBtn="{isAddToCart,isLoading , addProductToCard, countAddedToCart, changeProductCount}">
      <div class="template-cart-btn">
        <div
          v-if="countAddedToCart > 0"
          class="count-info"
        >
          <button
            class="btn-change"
            :disabled="isLoading"
            @click="changeProductCount(countAddedToCart - 1)"
          >
            <img
              src="../../assets/img/svg/cart/minus.svg"
              alt="minus"
            >
          </button>
          <div class="counter">{{ countAddedToCart }}</div>
          <button
            class="btn-change"
            :disabled="isLoading"
            @click="changeProductCount(countAddedToCart + 1)"
          >
            <img
              src="../../assets/img/svg/cart/plus.svg"
              alt="plus"
            >
          </button>
        </div>

        <button
          v-if="!isAddToCart"
          class="btn btn-primary"
          @click="addProductToCard({
            isClear:true
          })"
        >
          В корзину
        </button>
        <NuxtLink
          v-else
          to="/cart"
          class="link-to-cart btn btn-primary"
        >
          <span class="title">Добавлено
            <span>в корзину</span>
          </span>
          <span class="subtitle">Перейти в корзину</span>
        </NuxtLink>
      </div>
    </template>
  </add-to-card-button>
</template>

<script setup lang="ts">
import AddToCardButton from '~/components/widget/AddToCardButton.vue';
import type { IProduct } from '#sitis/internal/controllers/products/models/Product';

const props = defineProps<{
	product: IProduct;
	selectedOptions?: { [key: string]: any };
	isResetSettings?: boolean;
}>();

const emits = defineEmits<{
	errorList: [{ [key: number]: string }]
}>();
</script>

<style scoped lang="scss">
.template-cart-btn {
	display: flex;
	gap: 12px;

	& .count-info {
		height: 60px;
		border-radius: 16px;
		background: var(--gray);
		display: flex;
		min-width: 190px;
	}

	& .btn-change {
		padding: 0 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	& .counter {
		margin: 0 10px;
		min-width: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 140%;
		color: #393939;
	}

	.btn {
		height: 60px;
		width: 100%;
	}

	& .link-to-cart {
		width: 100%;
		display: flex;
		flex-direction: column;
		height: 60px;
		border-radius: 16px;

		& .title {
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			color: var(--white);
      margin: 0;
		}

		& .subtitle {
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			color: var(--silver);
		}
	}
}

@media (max-width: 640px) {
	.template-cart-btn {
		flex-direction: column;

		& .count-info {
			justify-content: center;
			height: 48px;
		}

		& .btn {
			height: 48px;
		}

		& .link-to-cart {
			height: 48px;
		}
	}
}
</style>
